"use client"

import { useEffect, useRef } from "react"

import { useLogin } from "./use-login"

const Login = () => {
  const { ssoProviderMutation } = useLogin()
  const redirecting = useRef(false) // In development mode, it useEffect is being called twice (strict mode) - this is to prevent that race between the two SSO API calls
  useEffect(() => {
    if (!redirecting.current) {
      redirecting.current = true
      ssoProviderMutation.mutate("workos")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty dependency array to run only once

  // No need to render anything
  return null
}

export { Login }
